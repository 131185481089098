<template>
	<div class="pbody-home pbody-block">
		<top-main v-if="!isMobileLayout" :games="shuffleGames" />
		<grid-all v-if="!isMobileLayout" :games="shuffleGames" />
		<mobile-banner v-if="isMobileLayout" :games="shuffleGames"></mobile-banner>
		<mobile-games v-if="isMobileLayout" :games="shuffleGames"></mobile-games>
	</div>
</template>

<script>
	import TopMain from '@/components/home/top-main'
	import GridAll from '@/components/home/grid-all'
	import MobileBanner from '@/components/home/mobile-banner'
	import MobileGames from '@/components/home/mobile-games'
	import {mapState} from 'vuex'
	export default {
		inject: ['app'],
		components: {
			TopMain,
			GridAll,
			MobileBanner,
			MobileGames
		},
		computed: {
			...mapState({
				games: state => state.games.games
			}),
			shuffleGames() {
				return this.games.sort(() => Math.random() - 0.5)
			},
			isMobileLayout() {
				return this.app.isMobileLayout
			}
		}
	}
</script>
<style lang="scss" scoped>
	.pbody-home {
		margin: 10px auto;
		max-width: 1410px;
		width: 100%;
	}
</style>