<template>
	<div class="mo-top-banner">
		<div class="xtx-carousel">
			<ul class="carousel-body">
				<li class="carousel-item" v-for="game,index in carouselItems" :key="game.handle" :class="{'fade': showIndex%carouselItems.length==index}">
					<game-item :game="game" style="width: 454px; height: 254px;" :showTitle="true" height="246"></game-item>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import GameItem from '@/components/common/game-item'
	export default {
		props: {
			games: {
				type: Array,
				default: () => []
			}
		},
		components: {
			GameItem
		},
		data() {
			return {
				showIndex: 0
			}
		},
		computed: {
			carouselItems() {
				return this.games.slice(12,15)
			}
		},
		mounted() {
			setInterval(() => {
				this.showIndex++
			}, 2000)
		}
	}
</script>

<style scoped>
	.xtx-carousel {
	    width: 100%;
	    min-width: 300px;
		height:256px;
	    position: relative;
	}
	
	.xtx-carousel:hover .carousel-btn {
	    opacity: 1;
	}
	
	.carousel-body {
	    width: 100%;
	    height: 100%;
	    margin: 0;
	}
	
	.carousel-body li {
	    list-style: none;
	}
	
	.carousel-item {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    opacity: 0;
	    -webkit-transition: opacity .5s linear;
	    transition: opacity .5s linear;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	}
	
	.fade {
	    opacity: 1;
	    z-index: 1;
	}
	
	.grid {
	    display: grid;
	    grid-template-columns: repeat(auto-fill,minmax(145px,0fr));
	    grid-auto-rows: auto;
	    grid-auto-flow: dense;
	    grid-gap: 10px;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    min-width: 1120px
	}
	
	.mo-top-banner {
	    overflow: hidden;
	    margin: 10px;
	}
</style>