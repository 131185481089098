<template>
	<div class="grid-all block-row-f">
		<div class="block-title">All Games</div>
		<div class="block-grid block-grid-f">
			<div class="ads-class">
				<p style="margin: 0px; color: #ffffff; font-size: 10px; text-align: center;">Advertisement</p>
				<div ></div>
			</div>
			<game-item v-for="game in allItems" :game="game" :key="game.handle" :showTitle="false" height="114"></game-item>
		</div>
	</div>
</template>

<script>
	import GameItem from '@/components/common/game-item'
	
	export default {
		components: {
			GameItem
		},
		props: {
			games: {
				type: Array,
				default: () => []
			}
		},
		computed: {
			allItems() {
				return this.games.slice(-50)
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.grid-all {
	    padding-bottom: 12px;
	    margin: 0 auto;
	}

	.block-title {
		margin: 12px 3px;
	}
	
	.ads-class {
	    grid-column: 4/6;
	    grid-row: 1/3;
	    overflow: hidden;
	}
	
	/* .ads-class2 {
	    height: 250px;
	    grid-column: 7/9;
	    grid-row: 4/6;
	    overflow: hidden;
	} */
</style>