<template>
	<div class="top-main block-row-f">
		<div class="top-main-left">
			<div class="block-title">Recommend Games</div>
			<div class="block-grid block-grid-f">
				<game-item v-for="game in leftItems" :game="game" :key="game.handle" height="114"></game-item>
			</div>
		</div>
		<div class="top-main-center">
			<div class="block-title" style="margin-left: 14px;">Editor's Picks</div>
			<div class="xtx-carousel" aotuplay="true">
				<ul class="carousel-body">
					<li class="carousel-item" v-for="game,index in carouselItems" :key="game.handle" :class="{'fade': showIndex%carouselItems.length==index}">
						<game-item :game="game" :showTitle="false" height="246" style="width: 454px; height: 254px;"></game-item>
					</li>
				</ul>
			</div>
		</div>
		<div class="top-main-right">
			<div class="block-title">New Games</div>
			<div class="block-grid block-grid-f">
				<game-item v-for="game in rightItems" :game="game" :key="game.handle" height="114"></game-item>
			</div>
		</div>
	</div>
</template>

<script>
	import GameItem from '@/components/common/game-item'
	export default {
		components: {
			GameItem
		},
		props: {
			games: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				showIndex: 0
			}
		},
		computed: {
			leftItems() {
				return this.games.slice(0,6)
			},
			rightItems() {
				return this.games.slice(6,12)
			},
			carouselItems() {
				return this.games.slice(12,15)
			}
		},
		mounted() {
			// setInterval(() => {
			// 	this.showIndex++
			// }, 2000)
		}
	}
</script>

<style lang="scss" scoped>
	.xtx-carousel {
	    width: 100%;
	    min-width: 300px;
	    height: 300px;
	    position: relative;
	}
	
	.xtx-carousel:hover .carousel-btn {
	    opacity: 1;
	}
	
	.carousel-body{
	    width: 100%;
	    height: 100%;
	    margin: 0;
	}
	
	.carousel-body li {
	    list-style: none;
	}
	
	.carousel-item {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    opacity: 0;
	    -webkit-transition: opacity .5s linear;
	    transition: opacity .5s linear;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	}
	
	.fade {
	    opacity: 1;
	    z-index: 1;
	}
	
	.top-main {
	    height: 300px;
	    margin: 0 auto 10px auto;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	}
	
	.top-main-left, .top-main-right {
	    width: 455px;
	}

	.top-main-center {
		flex: 1 1 0%;
	}
</style>