<template>
	<div>
		<div class="ads-class ad-bg">
			<p style="margin: 0px; color: #ffffff; font-size: 10px; text-align: center;">Advertisement</p>
			<div></div>
		</div>
		<div class="mo-grids">
			<game-item v-for="game in shuffleGames" :key="game.handle" :game="game" :showTitle="false" height="110"
				style="height: 100%; width: 100%; display: flex; border: none; padding: 0px; box-shadow: rgba(50, 50, 50, 0.65) 0px 1px 3px 0px;">
			</game-item>
		</div>
	</div>
</template>

<script>
	import GameItem from '@/components/common/game-item'
	export default {
		props: {
			games: {
				type: Array,
				default: () => []
			}
		},
		components: {
			GameItem
		},
		computed: {
			shuffleGames() {
				return this.games.slice(-30)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.ads-class {
	    min-height: 250px;
	}
</style>